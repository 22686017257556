import React from "react";

import LuxuryLiving from "../../src/assets/images/luxury_.png"; // Path to your image file
import Underconstruction from "../../src/assets/images/under_construction.jpg"; // Path to your image file
import Readytomove from "../../src/assets/images/ready_to_move.jpg"; // Path to your image file
import Newlaunch from "../../src/assets/images/new_launch.png"; // Path to your image file
import AffordableHousing from "../../src/assets/images/affordable_housing.jpg"; // Path to your image file

const ProjectTypesCard = () => {
  const showALL = async (type) => {
    const queryParams = new URLSearchParams({
      page: 1,
      property_type: [],
      property_status: [type],

      property_locality: [],

      property_location:
        localStorage.getItem("city") !== null
          ? [localStorage.getItem("city")]
          : [],
      property_builder: [],
      property_bhks: [],
      status: [],
      property_starting_price: 0,
      property_maximum_price: 15,
      property_name: "",
      is_featured: 0,
      is_infocused: 0,
    }).toString();
    window.open(
      `/${localStorage.getItem("city")}/properties?${queryParams}`,
      "_blank"
    );
  };
  const ShowAllAffordable = async () => {
    const queryParams = new URLSearchParams({
      page: 1,
      property_type: [],
      property_status: [],
      property_locality: [],

      property_location:
        localStorage.getItem("city") !== null
          ? [localStorage.getItem("city")]
          : [],
      property_builder: [],
      property_bhks: [],
      status: [],
      property_starting_price: 0,
      property_maximum_price: 2.5,
      property_name: "",
      is_featured: 0,
      is_infocused: 0,
    }).toString();
    window.open(
      `/${localStorage.getItem("city")}/properties?${queryParams}`,
      "_blank"
    );
  };
  const ShowAllLuxury = async () => {
    const queryParams = new URLSearchParams({
      page: 1,
      property_type: [],
      property_status: [],
      property_locality: [],

      property_location:
        localStorage.getItem("city") !== null
          ? [localStorage.getItem("city")]
          : [],
      property_builder: [],
      property_bhks: [],
      status: [],
      property_starting_price: 2.5,
      property_maximum_price: 15,
      property_name: "",
      is_featured: 0,
      is_infocused: 0,
    }).toString();
    window.open(
      `/${localStorage.getItem("city")}/properties?${queryParams}`,
      "_blank"
    );
  };

  return (
    <div className=" py-24 sm:py-32">
      <div
      //className="mx-auto max-w-7xl px-6 lg:px-8"
      >
        <h2
          style={{
            marginBottom: 30,
          }}
          className="text-center text-2xl md:text-4xl font-bold leading-none text-gray-900"
        >
          Exclusive projects in Bengaluru
        </h2>
        <h2 className="text-center text-md md:text-xl leading-none text-gray-900">
          walk in to the future of modern housing society
        </h2>

        <div className="mx-auto mt-10 grid max-w-7xl  grid-cols-1 sm:grid-cols-2 md:grid-cols-4   items-center gap-x-8 gap-y-10 sm:gap-x-10  ">
          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              //onClick={() => handleType("Apartment")}

              alt=""
              src={Newlaunch}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => showALL("New Launch")}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  10000+ properties{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    New Launch
                  </h3>
                </div>

                {/* <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95 bg-transparent">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Recusandae dolores, possimus pariatur animi temporibus
                  nesciunt praesentium dolore sed nulla ipsum eveniet corporis
                  quidem, mollitia itaque minus soluta, voluptates neque
                  explicabo tempora nisi culpa eius atque dignissimos. Molestias
                  explicabo corporis voluptatem?
                </p> */}
              </div>
            </div>
          </article>

          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              alt=""
              src={Underconstruction}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => showALL("Under Construction")}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  500+ properties{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    Under Construction
                  </h3>
                </div>
                {/* 
                <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95 bg-transparent">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Recusandae dolores, possimus pariatur animi temporibus
                  nesciunt praesentium dolore sed nulla ipsum eveniet corporis
                  quidem, mollitia itaque minus soluta, voluptates neque
                  explicabo tempora nisi culpa eius atque dignissimos. Molestias
                  explicabo corporis voluptatem?
                </p> */}
              </div>
            </div>
          </article>

          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              alt=""
              src={Readytomove}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => showALL("Ready to Move")}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  1000+ properties{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    Ready to move
                  </h3>
                </div>

                {/* <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95 bg-transparent">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Recusandae dolores, possimus pariatur animi temporibus
                  nesciunt praesentium dolore sed nulla ipsum eveniet corporis
                  quidem, mollitia itaque minus soluta, voluptates neque
                  explicabo tempora nisi culpa eius atque dignissimos. Molestias
                  explicabo corporis voluptatem?
                </p> */}
              </div>
            </div>
          </article>

          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              alt=""
              src={AffordableHousing}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => ShowAllAffordable()}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  1000+ properties{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    Affordable Housing
                  </h3>
                </div>

                {/* <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95 bg-transparent">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Recusandae dolores, possimus pariatur animi temporibus
                  nesciunt praesentium dolore sed nulla ipsum eveniet corporis
                  quidem, mollitia itaque minus soluta, voluptates neque
                  explicabo tempora nisi culpa eius atque dignissimos. Molestias
                  explicabo corporis voluptatem?
                </p> */}
              </div>
            </div>
          </article>
          {/* 
          <article
            // style={{ width:  300 }}
            className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg"
          >
            <img
              alt=""
              src={LuxuryLiving}
              className="absolute inset-0 h-full w-full object-cover"
            />

            <div
              onClick={() => ShowAllLuxury()}
              className="relative bg-gradient-to-t bg-transparent from-gray-900/50 to-gray-900/25 pt-32 sm:pt-48 lg:pt-64"
            >
              <div className="p-4 sm:p-6 bg-transparent">
                <time
                  datetime="2022-10-10"
                  className="block text-xs text-white/90 bg-transparent"
                >
                  {" "}
                  500+ properties{" "}
                </time>

                <div>
                  <h3 className="mt-0.5 text-lg text-white bg-transparent">
                    Luxury
                  </h3>
                </div>

                 <p className="mt-2 line-clamp-3 text-sm/relaxed text-white/95 bg-transparent">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Recusandae dolores, possimus pariatur animi temporibus
                  nesciunt praesentium dolore sed nulla ipsum eveniet corporis
                  quidem, mollitia itaque minus soluta, voluptates neque
                  explicabo tempora nisi culpa eius atque dignissimos. Molestias
                  explicabo corporis voluptatem?
                </p>  
              </div>
            </div>
          </article> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectTypesCard;
